import React, { useState, useEffect } from 'react';
import {
    Layout,
    Play,
    Users,
    Edit2,
    ChevronLeft,
    ChevronRight,
    Box,
  } from 'react-feather';
import games from 'config/games';
import SidebarItem from './components/SidebarItem';
import './styles.css';
import useFetchFromApi from 'utility/useFetchFromApi';
import colors from 'config/colors';

const Sidebar = ({ 
    goToLayout, 
    goToFittingRoom, 
    goToProfiles, 
    goToLayoutEditor, 
    goToGames, 
    currentPage, 
    dataLayout 
}) => {
    const profileId = localStorage.getItem('profileId');
    const { loading, error, data, refetch } = useFetchFromApi(`layout/profileLayouts/${profileId}`);
    const [sidebar, setSidebar] = useState(false);
    const [buttonSidebar, setButtonSidebar] = useState(false);
    const [showLayouts, setShowLayouts] = useState(false);
    const [showGames, setShowGames] = useState(false);
    const [opacity, setOpacity ] = useState('0.4');
    const [pointerEvent, setPointerEvent] = useState('none');

    const toggleSidebar = () => {
        setSidebar(sidebar === false ? true : false);
        setButtonSidebar(buttonSidebar === false ? true : false);
    };

    const handleClickLayouts = (id) => {
        goToLayout(id);
    };

    const handleClickGame = (id) => {
        setShowGames(false);
        goToGames(id);
        setSidebar(false);
        setButtonSidebar(false);
    };

    const handleClickFittingRoom= (id) => {
        setShowLayouts(false);
        goToFittingRoom(id);
        setSidebar(false);
        setButtonSidebar(false);
    };
    const handleClickProfiles= (id) => {
        setShowLayouts(false);
        goToProfiles(id);
        setSidebar(false);
        setButtonSidebar(false);
    };

    const borderWidth = dataLayout?.border?.width ?? 0;

    useEffect(() => {
        if(data.length === 0) {
            setOpacity('0.4');
            setPointerEvent('none');
        } else {
            setOpacity('1');
            setPointerEvent('all');
        }
      }, [data]);

    return (
        currentPage !== 'layoutEdit' &&
        <>
            <div 
                onClick={toggleSidebar} 
                className='sidebar-wrapper-button'
                style={{ 
                    right: buttonSidebar ===  true ? `calc(10% + ${borderWidth}px)`: '0.5%',
                    height: buttonSidebar === true ? '10vh' : '100vh',
                }}
            >
                {buttonSidebar === true ? <ChevronRight /> : <ChevronLeft /> }
            </div>
            <div 
                className='sidebar-wrapper'
                style={{ right: sidebar === true ? `calc(0.5% + ${borderWidth}px)`: '-10%'}}
            >
                <div className="sidebar-item-list">
                    <>
                        <SidebarItem onClick={goToLayoutEditor} text="Editor" icon={<Edit2 />}/>
                        <SidebarItem opacity={opacity} pointerEvent={pointerEvent} onClick={() => setShowLayouts(showLayouts === false ? true : false)} text={showLayouts === false ? 'Layouts' : 'Close'} icon={<Layout />}/>
                        {showLayouts &&
                            <>
                            {data.map((item, index) =>
                                <SidebarItem 
                                    key={index} 
                                    onClick={() => handleClickLayouts(item.id)} 
                                    text={item.name}
                                    textColor={dataLayout?.id === item.id ? `${colors.primary}` : `${colors.secondary}`}
                                    border={dataLayout?.id === item.id ? `1px solid ${colors.primary}` : `1px solid ${colors.secondary}`}
                                />
                            )}
                            </>
                        }
                        <SidebarItem onClick={() => setShowGames(showGames === false ? true : false)} text={showGames === false ? 'Games' : 'Close'}icon={<Box/>}/>
                        {showGames &&
                            <>
                            {games.map((item, index) =>
                                <SidebarItem key={index} onClick={() => handleClickGame(item.id)} text={item.name} />
                            )}
                            </>
                        }
                        <SidebarItem onClick={handleClickFittingRoom} text="Fitting Room" icon={<Play />}/>
                        <SidebarItem onClick={handleClickProfiles} text="Profiles" icon={<Users />}/>
                    </>
                </div>
            </div>
        </>
    );
};

export default Sidebar;