import React, { useState } from "react";

const MultiplayerGame = ({multiPlayerGame, setMultiPlayerGame}) =>{
    function Square({ value, onClick }) {

          return (
            <div className="square" onClick={onClick}>
              {value}
            </div>
          );
        }
        
        function Restart({ onClick }) {
        
          return (
            <button className="restart" onClick={onClick}>
              Play Again
            </button>
          );
        }
        function Menu({onClick}){
            return (
                <button className="restart" onClick={onClick}>Menu</button>
            );
      }
        
          const [ squares, setSquares ] = useState(Array(9).fill(null));
          const [ isXNext, setIsXNext ] = useState(true);
          const nextSymbol = isXNext ? "X" : "O";
          const winner = calculateWinner(squares);
        
        
        
          function getStatus() {
            if (winner) {
              return "Winner: " + winner;
            } else if (isBoardFull(squares)) {
              return "Draw!";
            } else {
              return "Next Player: " + nextSymbol;
            }
          }
        
          function renderSquare(i) {
            return (
              <Square
                value={squares[i]}
                onClick={() => {
                  if (squares[i] != null || winner != null) {
                    return;
                  }
                  const nextSquares = squares.slice();
                  nextSquares[i] = nextSymbol;
                  setSquares(nextSquares);
        
                  setIsXNext(!isXNext); // toggle turns
                }}
              />
            );
          }
        
          function renderRestartButton() {
            return (
              <Restart
                onClick={() => {
                  setSquares(Array(9).fill(null));
                  setIsXNext(true);
                }}
              />
            );
          }
          function renderMenuButton() {
            return(
                <Menu 
                onClick={() => {
                    setMultiPlayerGame(false);
                    setSquares(Array(9).fill(null));
                  setIsXNext(true);
                }} 
                />
            );
        }
        
          return (
                <div className="game"> 
                <span>MultiPlayer</span>
                    <div className="game-info">{getStatus()}</div>
                    <div className="game-board">
                        <table>
                            <tr>
                                <td>{renderSquare(0)}</td>
                                <td>{renderSquare(1)}</td>
                                <td>{renderSquare(2)}</td>
                            </tr>
                            <tr>
                                <td>{renderSquare(3)}</td>
                                <td>{renderSquare(4)}</td>
                                <td>{renderSquare(5)}</td>
                            </tr>
                            <tr>
                                <td>{renderSquare(6)}</td>
                                <td>{renderSquare(7)}</td>
                                <td>{renderSquare(8)}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="restart-button">
                  <div>{renderRestartButton()}</div>
                  <div>{renderMenuButton()}</div>
                  </div>
                </div>
          );
        
        function calculateWinner(squares) {
          const possibleLines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
          ];
          // go over all possibly winning lines and check if they consist of only X's/only O's
          for (let i = 0; i < possibleLines.length; i++) {
            const [a, b, c] = possibleLines[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
              return squares[a];
            }
          }
          return null;
        }
        
        function isBoardFull(squares) {
          for (let i = 0; i < squares.length; i++) {
            if (squares[i] == null) {
              return false;
            }
          }
          return true;
        }
    }
export default MultiplayerGame;