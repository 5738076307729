import React from 'react';

function Spinner(props){
    return(
        <div>
            <p>Loading...</p>
        </div>
    );
}

export default Spinner;