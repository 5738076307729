import { useState, useEffect } from 'react';
import api from 'utility/api';

const useFetchFromApi = (url) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);

    const fetchData = () => {
        setLoading(true);
        api.get(url)
        .then(function (response) {
            console.log('API Response:', response);
            if(response?.data?.ResultCode === 1){
                setData(response?.data?.Data);
            }else if(response?.data?.Result === 'Error'){
                setError(response?.data?.Error);
            }else{
                setError('Unkown error');
            }
        })
        .catch(function (error) {
            console.log(error);
            setError(error.response);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const refetch = () => {
        fetchData();
    }

    useEffect(() => {
        if (!url) return;
        fetchData();
    }, [url]);

    return { data, loading, error, refetch };
};

export default useFetchFromApi;