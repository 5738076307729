import { keyframes } from 'styled-components';

const borderAnimations = {
    rgbFade: keyframes`
      0% {
        border-color: red;
        filter: drop-shadow(0 0 20px red);
      }
      20% {
        border-color: blue;
        filter: drop-shadow(0 0 20px blue);
      }
      25% {
        border-color: purple;
        filter: drop-shadow(0 0 20px purple);
      }
      60% {
        border-color: green;
        filter: drop-shadow(0 0 20px green);
      }
      80% {
        border-color: yellow;
        filter: drop-shadow(0 0 20px yellow);
      }
      100% {
        border-color: red;
        filter: drop-shadow(0 0 20px red);
      }
    `
};
export default borderAnimations;