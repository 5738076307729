import React, { Suspense, useEffect, useState } from 'react';
import { renderWidget } from 'config/widgets';
import './styles.scss';
import Spinner from 'components/Spinner';
import useFetchFromApi from 'utility/useFetchFromApi';
import MercureSubscriber from 'components/MercureSubscriber';
import MirrorBackground from 'components/mirror-background';
import Sidebar from 'components/sidebar';
import FittingRoom from 'components/fitting-room';
import Profiles from 'components/profiles';
import AnimatedComponent from 'components/animated-component';
import LayoutEditor from 'components/layout-editor';
import TicTacToe from 'components/games/tic-tac-toe';
import FourInALine from 'components/games/four-in-a-line';
import Portal from 'utility/Portal';
import styled from 'styled-components';
import getAnimation from 'utility/getAnimation';
import api from 'utility/api';

function Main(props) {
  const [currentPage, setCurrentPage] = useState('profiles');
  const [layoutId, setLayoutId] = useState(process.env.REACT_APP_LAYOUT_ID);
  const [gameId, setGameId] = useState(1)
  const [selectedProfile, setSelectedProfile] = useState(process.env.REACT_APP_USER_PROFILE_ID);
  const [gridHeight, setGridHeight] = useState(21);
  const [gridWidth, setGridWidth] = useState(9);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const { loading: loadingDevice, error: errorDevice, data: dataDevice, refetch: refetchDevice} = useFetchFromApi(`device/deviceData/${process.env.REACT_APP_DEVICE_SERIAL}`);
  
  const setGridSize = () => {
    if (data?.settings?.orientation  === 'vertical') {
      setGridHeight(21);
      setGridWidth(9);
    }else{
      setGridHeight(9);
      setGridWidth(21);
    }
  };

  const onLayoutMercureMsg = (e) => {
    const data = JSON.parse(e?.data);
    if (data?.status === 'Updated layout') {
      getLayoutDetails(dataDevice?.layout?.id);
    }
  };

  const onDeviceMercureMsg = (e) => {
    const data = JSON.parse(e?.data);
    if (data?.status === 'Set new layout') {
      setLayoutId(data?.layoutId);
    }
  };

  const goToLayout = (id) => {
    api.post(`device/setLayout`, {
      layoutId: id,
      deviceId: dataDevice?.id
    })
      .then(function (response) {
          if(response?.data?.ResultCode === 1){
              setLayoutId(id);
              setCurrentPage('layout');
          }else if(response?.data?.Result === 'Error'){
              console.log(error);
          }else{
              console.log(error);
          }
      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(() => {
          setLoading(false);
      })
  };

  const goToGames = (gameId) => {
    setGameId(gameId);
    setCurrentPage('games');
  }

  const goToFittingRoom = () => {
    setCurrentPage('fitting-room');
  };

  const goToProfiles = () => {
    setCurrentPage('profiles');
  };

  const goToLayoutEditor = () => {
    setCurrentPage('layoutEdit');
  }

  const onChangeProfile = (id) => { 
    if(id === selectedProfile && dataDevice?.layout?.id){
      setCurrentPage('layout');
    }else{
      setSelectedProfile(id);
    };
  };

  const getLayoutDetails = (id) => {
    api.get(`layout/details/${id}`)
      .then(function (response) {
          if(response?.data?.ResultCode === 1){
              setData(response?.data?.Data);
          }else if(response?.data?.Result === 'Error'){
              console.log(error);
          }else{
              console.log(error);
          }
      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(() => {
          setLoading(false);
      })
  }

  const Border = styled.div`
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 99;
        pointer-events: none;
        border: ${data?.border?.width}px ${data?.border?.type} ${data?.border?.color};
        animation: ${getAnimation(data?.border?.id)};
        filter: drop-shadow(0 0 20px ${data?.border?.color});
  `;

  useEffect(() => {
    refetchDevice();
  }, [layoutId]);

  useEffect(() => {
    refetchDevice();
  }, [selectedProfile]);

  useEffect(() => {
    if(data) setCurrentPage('layout');
  }, [data]);

  useEffect(() => {
    setGridSize();
  }, [data]);

  useEffect(() => {
    if(dataDevice?.layout?.id) setLayoutId(dataDevice?.layout?.id);

    if(dataDevice?.layout?.id){
      getLayoutDetails(dataDevice?.layout?.id);
    }
  }, [dataDevice]);

  return (
      <>
      {!loading &&
      <>
      <Portal>
          <Border/>
      </Portal>
      <div className="main-container" style={{border: `${data?.border?.width}px ${data?.border?.type} ${data?.border?.color}`}}>
        <div className="glass-effect-layout">
        </div>
        <Sidebar
          goToProfiles={goToProfiles}
          goToLayout={goToLayout}
          goToFittingRoom={goToFittingRoom}
          goToGames={goToGames}
          goToLayoutEditor={goToLayoutEditor}
          currentPage={currentPage}
          dataLayout={data}
        />
          <MercureSubscriber onMessage={onLayoutMercureMsg} topic={`edit-layout-${layoutId}`} />
          <MercureSubscriber onMessage={onDeviceMercureMsg} topic={`device-${process.env.REACT_APP_DEVICE_SERIAL}`} />
          {error !== '' &&
            <p>{error}</p>
          }
          <AnimatedComponent
            isVisible={currentPage === 'fitting-room'}
            delay={1}
            animation="fade"
          >
            <FittingRoom />
          </AnimatedComponent>

          <AnimatedComponent
            isVisible={currentPage === 'games'}
            delay={1}
            animation="fade"
          >
            {gameId === 1 && <TicTacToe />}
            {gameId === 2 && <FourInALine />}
          </AnimatedComponent>

          <AnimatedComponent
            isVisible={currentPage === 'layout'}
            delay={1.5}
            animation="fade"
          >
            {data !== [] &&
              <Suspense fallback={<Spinner />}>
                <Portal>
                  <MirrorBackground data={data}/>
                </Portal>
                {Object.keys(data?.widgets).map((key, index) =>
                  <div
                    key={index}
                    className="widget-container"
                    style={{
                      width: `${(100 / gridWidth) * data?.widgets[key].width}%`,
                      height: `${(100 / gridHeight) * data?.widgets[key].height}%`,
                      top: `${data?.widgets[key].row * (100 / gridHeight)}%`,
                      left: `${data?.widgets[key].cell * (100 / gridWidth)}%`,
                    }}>
                    {renderWidget(key, { height: data?.widgets[key].height, width: data?.widgets[key].width, widgetSettings: data?.widgets[key] })}
                  </div>
                )}
              </Suspense>
            }
          </AnimatedComponent>

          <AnimatedComponent
            isVisible={currentPage === 'layoutEdit'}
            delay={1}
            animation="fade"
          >
            <LayoutEditor data={data} layoutId={layoutId} goToLayout={goToLayout} />
          </AnimatedComponent>

          <AnimatedComponent
            isVisible={currentPage === 'profiles'}
            delay={1.5}
            animation="fade"
          >
            <Profiles onChangeProfile={onChangeProfile}/>
          </AnimatedComponent>
        </div>
        </>
      }
      </>
  );
}

export default Main;