import React from 'react';

const VideoList = ({ videos, handleVideoSelection }) => {
    return (
        <div className="clothing-videos-wrapper-outer mt-2">
            <div className="clothing-videos-wrapper-inner p-1">
                {videos.map((item, i) => 
                    <div key={i} className="clothing-video-thumb-container">
                        <img
                            src={`${process.env.REACT_APP_API_URL}uploads/videos/${item.thumbnail}`}
                            onClick={() => handleVideoSelection(item.src)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoList;