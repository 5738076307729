const games = [
    {
        id: 1,
        name: 'TicTacToe',
    },
    {
        id: 2,
        name: '4-Line',
    },
];

export default games;