import React, { useState, useEffect } from 'react';
import './styles.scss';
import {
  Button,
  Row,
  Col,
} from 'reactstrap';
import { getRowLimit, getCellLimit } from './utility/getBoundaries';
import parseCellName from './utility/parseCellName';
import Grid from './components/Grid';
import { renderWidget } from 'config/widgets';
import api from 'utility/api';
import { toast } from 'react-toastify';
import SidebarEditor from './components/SidebarEditor';
import { useTranslation } from 'react-i18next';

function LayoutEditor({ data, layoutId, goToLayout }) {
  const { t } = useTranslation('layout-editor');
  const [gridHeight, setGridHeight] = useState(21);
  const [gridWidth, setGridWidth] = useState(9);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [touchDragEl, setTouchDragEl] = useState('');
  const [touchTargetCell, setTouchTargetCell] = useState('');
  const [layout, setLayout] = useState({});
  const [currentCell, setCurrentCell] = useState('');
  const [movingX, setMovingX] = useState(null);
  const [movingY, setMovingY] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const setGridSize = () => {
    if (data?.settings?.orientation  === 'vertical') {
      setGridHeight(21);
      setGridWidth(9);
    }else{
      setGridHeight(9);
      setGridWidth(21);
    }
  };

  const saveLayoutChanges = () => {
    const formData = new FormData();
    formData.append('widgets', JSON.stringify(layout));
    formData.append('orientation', data?.settings?.orientation);
    formData.append('backgroundImageOpacity', data?.settings?.backgroundImageOpacity);

    api.post(
      `layout/edit/${layoutId}`,
      formData,
      {
        headers: {
          'X-AUTH-TOKEN': process.env.REACT_APP_ACCESS_TOKEN,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .then(function (resp) {
      //console.log(resp);
      if (resp.data.ResultCode === 1) {
        toast.success(t('layouts.save-success'));
        goToLayout(layoutId);
      } else {
        toast.error(t('layouts.error-saving'));
      }
    })
    .catch(function (err) {
      // console.log(err.response);
      toast.error(t('layouts.error-saving'));
    })
  };
  
  const onTouchStart = (e, name) => {
    setSelectedWidget(name);
    setTouchDragEl(name);
    setTouchTargetCell('');

    // console.log(e.targetTouches);

    const x = e.targetTouches[0].clientX;
    const y = e.targetTouches[0].clientY;

    // console.log('--------------');
    // console.log('Div Left:', e.targetTouches[0].target.offsetLeft);
    // console.log('Div Top:', e.targetTouches[0].target.offsetTop);
    // console.log('Screen Left:', x);
    // console.log('Screen Top:', y);

    setMovingX({
      ...movingX,
      [name]: x - e.targetTouches[0].target.offsetLeft,
    });
    setMovingY({
      ...movingY,
      [name]: y - e.targetTouches[0].target.offsetTop,
    });
    setIsDragging(false);
  };

   // console.log('Moving Left:', movingX);
   // console.log('Moving Top:', movingY);

  

  const onTouchMove = (e, clickedCell, widgetName ) => {

    e.preventDefault();
    e.stopPropagation();
    const x = e.targetTouches[0].clientX;
    const y = e.targetTouches[0].clientY;

    setMovingX({
      ...movingX,
      [widgetName]: x - e.targetTouches[0].target.offsetLeft,
    });
    setMovingY({
      ...movingY,
      [widgetName]: y - e.targetTouches[0].target.offsetTop,
    });

    const hoverEl = document.elementFromPoint(x, y);
    
    if(!hoverEl) return null;

    if(hoverEl.classList.value.includes('layout-creator-cell')){
      setTouchTargetCell(hoverEl.id);
    }
    setIsDragging(false);
    if(clickedCell) onTouchEnd(clickedCell);
  };

  const onTouchEnd = (clickedCell, widgetName) => {
   // console.log('clickedCell:', clickedCell)
   
    if(touchTargetCell === '') return null;
    // get the cell within the widget card where the dragEvent started
    const dragStartRow = clickedCell.split('-')[1];
    const dragStartCell = clickedCell.split('-')[2];
    
    // calculate where to drop the widget based on where within the widget the dragEvent started and based on widget size (avoid placing part of widget outside the grid)
    const targetCellArr = touchTargetCell.split('-');
    const limitedRow = getRowLimit(
      targetCellArr[1],
      dragStartRow,
      layout[touchDragEl]?.height,
      gridHeight
    );
    const limitedCell = getCellLimit(
      targetCellArr[2],
      dragStartCell,
      layout[touchDragEl]?.width,
      gridWidth
    );
    
    setLayout({
      ...layout,
      [touchDragEl]: {
        ...layout[touchDragEl],
        row: limitedRow,
        cell: limitedCell,
      },
    });

    // console.log('Row:', limitedRow);
    // console.log('Cell:', limitedCell);
    
    setIsDragging(false);
  };

  const isWidgetSelected = (name) => {
    if (selectedWidget === name) return true;
    return false;
  };

  const increaseWidth = (widgetName) => {
    // prevent widget from escaping sizing boudaries
    const currentCell = layout[widgetName]?.cell;
    const newWidth = layout[widgetName].width + 1;
    if (newWidth < 1 || newWidth > gridWidth - currentCell) return false;

    setLayout({
      ...layout,
      [widgetName]: {
        ...layout[widgetName],
        width: newWidth,
      },
    });
  };

  const decreaseWidth = (widgetName) => {
    // prevent widget from escaping sizing boudaries
    const currentCell = layout[widgetName]?.cell;
    const newWidth = layout[widgetName].width - 1;
    if (newWidth < 1 || newWidth > gridWidth - currentCell) return false;

    setLayout({
      ...layout,
      [widgetName]: {
        ...layout[widgetName],
        width: newWidth,
      },
    });
  };

  const increaseHeight = (widgetName) => {
    const currentRow = layout[widgetName]?.row;

    // prevent widget from escaping sizing boudaries
    const newHeight = layout[widgetName].height + 1;
    if (newHeight < 1 || newHeight > gridHeight - currentRow) return false;

    setLayout({
      ...layout,
      [widgetName]: {
        ...layout[widgetName],
        height: newHeight,
      },
    });
  };

  const decreaseHeight = (widgetName) => {
    // prevent widget from escaping sizing boudaries
    const currentRow = layout[widgetName]?.row;
    const newHeight = layout[widgetName].height - 1;
    if (newHeight < 1 || newHeight > gridHeight - currentRow) return false;

    setLayout({
      ...layout,
      [widgetName]: {
        ...layout[widgetName],
        height: newHeight,
      },
    });
  };

  useEffect(() => {
    setLayout(data?.widgets);
  }, [data]);

  useEffect(() => {
    setGridSize();
  }, [data]);

  //console.log('currentCell:', currentCell)
  // console.log('touchTargetCell:', touchTargetCell)

  return (
    <div className="layout-creator-grid-wrapper">
      <div className="d-flex justify-content-end w-100">
        <SidebarEditor
          goToLayout={goToLayout}
          layoutId={layoutId}
          saveLayoutChanges={saveLayoutChanges}
          increaseHeight={increaseHeight}
          decreaseHeight={decreaseHeight}
          increaseWidth={increaseWidth}
          decreaseWidth={decreaseWidth}
          selectedWidget={selectedWidget}
        />
      </div>
      <div className="layout-creator-grid" onClick={() => setSelectedWidget(null)}>
        {data &&
          <>
            {Object.keys(layout).map((key, index) => (
              <div
                onTouchStart={(e) => onTouchStart(e, key)}
                onTouchMove={(e) => onTouchMove(e, currentCell, key)}
                onTouchEnd={(e) => onTouchEnd(currentCell)}
                onClick={(e) => e.stopPropagation()}
                key={index}
                draggable
                className= "layout-editor-widget-container"
                style={{
                  position: 'absolute',
                  width: `${(100 / gridWidth) * layout[key].width}%`,
                  height: `${(100 / gridHeight) * layout[key].height}%`,
                  top: isDragging === true ? `${movingY[key]}px` : `${layout[key].row * (100 / gridHeight)}%`,
                  left: isDragging === true ? `${movingX[key]}px` : `${layout[key].cell * (100 / gridWidth)}%`,
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  zIndex: isWidgetSelected(key) ? '99' : '1',
                }}
              >
                <div className={ isWidgetSelected(key) ? "layout-editor-widget-container-selected" : "d-none"}>
                <span />
                <span />
                <span />
                <span />
                <div className={ isWidgetSelected(key) ? "glass-effect" : "d-none"}> 
                </div>
                </div>
                {Array(layout[key].height)
                  .fill(1)
                  .map((row, indexRow) => (
                    <div key={indexRow} className="widget-card-row">
                      {/* Create cells */}
                      {Array(layout[key].width)
                        .fill(1)
                        .map((cell, indexCell) => (
                          <div
                            /* onMouseEnter saves the cell within the widget card currently being hovered */
                            onMouseEnter={() =>
                              setCurrentCell(parseCellName(indexRow, indexCell))
                            }
                            onTouchStart={() => {
                              setCurrentCell(parseCellName(indexRow, indexCell))
                            }}
                            key={indexCell}
                            className="widget-card-cell"
                            id={parseCellName(indexRow, indexCell)}
                          />
                        ))}
                    </div>
                ))}
                <div className="layout-editor-widget-preview">
                  {renderWidget(key, { height: data?.widgets[key].height, width: data?.widgets[key].width, widgetSettings: data?.widgets[key] })}
                </div>
              </div>
            ))}
          </>
        }

        <Grid gridHeight={gridHeight} gridWidth={gridWidth}/>
      </div>
    </div>
  );
}

export default LayoutEditor;
