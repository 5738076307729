import React from 'react';

class MercureSubscriber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  };

  componentDidMount(){
    const es = new EventSource(`${process.env.REACT_APP_API_URL}.well-known/mercure?topic=${this.props.topic}`);
    es.onmessage = e => {
        this.props.onMessage(e);
    }
  };

  render(){
    return(
      <div style={{ display: 'none' }}>
      </div>
    );
  };
};

export default MercureSubscriber;