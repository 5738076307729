import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(initReactI18next)

  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./translations/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  }))
  
  .init({
    interpolation: { escapeValue: false },  
    lng: 'pt',
    locales: ['pt', 'en'],
    defaultLocal: ['pt'],
    react: {
      useSuspense: false,
    },
  });


  
export default i18n;
