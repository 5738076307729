import { Sin, time } from "@tensorflow/tfjs-core";
import { initializers } from "@tensorflow/tfjs-layers";
import { render } from "@testing-library/react";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import SingleGame from "./components/single-game";
import MultiplayerGame from "./components/multiplayer-game";
import "./styles.css";


const TicTacToe = (props) => {
  
  const [ singlePlayerGame , setSinglePlayerGame] = useState(false);
  const [ multiPlayerGame , setMultiPlayerGame] = useState(false);
  return(
    <div className="container">
              <div className="game">
              <span className={singlePlayerGame || multiPlayerGame ? "d-none" : ""}>Tic Tac Toe Game</span>
              <div className={singlePlayerGame || multiPlayerGame ? "d-none" : "game-container"}>
              <button onClick={() => setSinglePlayerGame(true)}>Single Player</button>
              <button onClick={() => setMultiPlayerGame(true)}>MultiPlayer</button>
              </div>
              <div className={singlePlayerGame ? "game-fill" : "d-none"}>
              <SingleGame singlePlayerGame={singlePlayerGame} setSinglePlayerGame={setSinglePlayerGame}/>
              </div>
              <div className={multiPlayerGame ? "game-fill" : "d-none"}>
              <MultiplayerGame multiPlayerGame={multiPlayerGame} setMultiPlayerGame={setMultiPlayerGame}/>
              </div>
              </div> 
    </div>
  );
}
export default TicTacToe;