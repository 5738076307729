import React from 'react';


const SidebarEditorItem = ({ onClick, text, icon, bgColor, bgColorCode }) => {
  return (
    <div className={`sidebar-item cursor-pointer d-flex justify-content-center align-items-center mb-1 bg-${bgColor}`} style={{backgroundColor: bgColorCode}} onClick={onClick}>
      {icon}
      <p>{text}</p>
    </div>
  );
};

export default SidebarEditorItem;