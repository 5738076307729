import React from 'react';
import './styles.scss';

const MirrorBackground = ({data}) => {
    const fileSrc = data?.backgroundImage?.path
    const layoutOpacity = data?.settings?.backgroundImageOpacity;

    const fileExtension = fileSrc.substring(fileSrc.lastIndexOf('.') + 1, fileSrc.length) || fileSrc;

    const videoExtensions = ['mp4', 'mov', 'ogg', 'webm'];
    const imageExtensions = ['jpg', 'gif', 'png', 'jpeg'];
    
    return (
        <div className="mirror-background-container">
            {videoExtensions.includes(fileExtension) &&
            <video 
                className="mirror-background-container-file"
                muted 
                autoPlay
                loop 
                src={`${process.env.REACT_APP_API_URL}uploads/bg_images/${fileSrc}`} style={{filter: `brightness(${layoutOpacity}%)`}}
            >
            </video>
            }
            {imageExtensions.includes(fileExtension) &&
            <img className="mirror-background-container-file" src={`${process.env.REACT_APP_API_URL}uploads/bg_images/${fileSrc}`} style={{filter: `brightness(${layoutOpacity}%)`}}></img>
            }
        </div>
    )
};

export default MirrorBackground;