import React from 'react';
import {
  User,
} from 'react-feather';
import useFetchFromApi from 'utility/useFetchFromApi';
import colors from 'config/colors';

const Profile = ({ profile, selectProfile}) => {

  const profileId = profile.id;
    const { loading, error, data, refetch } = useFetchFromApi(`layout/profileLayouts/${profileId}`);

  return (
    <div 
      className="profile-item-container p-2 d-flex justify-content-center align-items-center m-2 cursor-pointer"
      style={{ border: profile?.id == localStorage.getItem('profileId') ? `1px solid ${colors.primary}` : `1px solid ${colors.secondary}`}}
      onClick={() => selectProfile(profile.id,data)}
    >
      <User 
        size={52} 
        style={{ stroke: profile?.id == localStorage.getItem('profileId') ? colors.primary : colors.secondary}}
      />
      <span
        style={{ color: profile?.id == localStorage.getItem('profileId') ? colors.primary : colors.secondary}}
      >
        {profile.name}
      </span>
    </div>
  );
};

export default Profile;