import React from 'react';

const SidebarItem = ({ onClick, text, icon, bgColor, bgColorCode, textColor, opacity, pointerEvent, border }) => {

  return (
    <div 
      className={`sidebar-item cursor-pointer d-flex justify-content-center align-items-center mb-1 bg-${bgColor}`} 
      style={{backgroundColor: bgColorCode, opacity: opacity, pointerEvents: pointerEvent, color: textColor, border: border}} 
      onClick={onClick}
    >
      {icon}
      <p>{text}</p>
    </div>
  );
};

export default SidebarItem;