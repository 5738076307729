import { css } from 'styled-components';
import borderAnimations from 'config/border-animations';

function getAnimation(id) {
    var animation = props =>
    css`
      none;
    `
    if(id === 6 ){
      animation = props =>
    css`
      ${borderAnimations.rgbFade} 20s infinite alternate;
    `
    };
    return animation;
};

export default getAnimation;