import React from 'react';

const Grid = ({ gridHeight, gridWidth }) => {
  return (
    <>
      {/* Create rows */}
      {Array(parseInt(gridHeight))
          .fill(1)
          .map((row, indexRow) => (
            <div key={indexRow} className={`layout-creator-row`}>
              {/* Create cells */}
              {Array(parseInt(gridWidth))
                .fill(1)
                .map((cell, indexCell) => (
                  <div
                    key={indexCell}
                    className={`layout-creator-cell 
                                ${
                                  indexRow === 0 && indexCell === 0
                                    ? 'layout-creator-cell-top-left '
                                    : ''
                                }
                                ${
                                  indexRow ===
                                    Array(gridHeight).fill(1).length - 1 &&
                                  indexCell === 0
                                    ? 'layout-creator-cell-bottom-left '
                                    : ''
                                }
                                ${
                                  indexRow === 0 &&
                                  indexCell ===
                                    Array(gridWidth).fill(1).length - 1
                                    ? 'layout-creator-cell-top-right '
                                    : ''
                                }
                                ${
                                  indexCell ===
                                    Array(gridWidth).fill(1).length - 1 &&
                                  indexRow ===
                                    Array(gridHeight).fill(1).length - 1
                                    ? 'layout-creator-cell-bottom-right '
                                    : ''
                                }
                                ${
                                  indexCell === 0
                                    ? 'layout-creator-cell-left '
                                    : ''
                                }
                                ${
                                  indexRow === 0
                                    ? 'layout-creator-cell-top '
                                    : ''
                                }
                            `}
                  id={`cell-${indexRow}-${indexCell}`}
                />
              ))}
          </div>
        ))}
    </>
  );
};

export default Grid;