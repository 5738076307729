import React, { useEffect, useState } from 'react';
import './styles.css';

const AnimatedComponent = ({ children, isVisible, delay, animation }) => {
  const [renderComponent, setRenderComponent] = useState(false);

  const showComponent = () => {
    setTimeout(() => {
      setRenderComponent(true);
    }, delay * 1000);
  };

  const hideComponent = () => {
    setTimeout(() => {
      setRenderComponent(false);
    }, delay * 1000);
  };

  const getAnimationClass = () => {
    if(isVisible) return `${animation}-in`;
    else return `${animation}-out`;
  };

  useEffect(() => {
    if(isVisible) showComponent();
    else hideComponent();
  }, [isVisible]);

  if(!renderComponent) return <></>;
  return (
    <div
      className="animated-component-container"
      style={{
        animation: `${getAnimationClass()} ease-in-out ${delay}s`
      }}>
      {children}
    </div>
  );
};

export default AnimatedComponent;