import React from 'react';
import Webcam from 'react-webcam';
import {
    Button,
    Row,
} from 'reactstrap';
import {
    Circle,
    StopCircle,
    Upload,
} from 'react-feather';

const WebcamCapture = ({webcamRef, handleStopCaptureClick, capturing, handleStartCaptureClick, recordedChunks, handleUpload}) => {
    return (
        <Row className="webcam-container justify-content-center">
            <Row className="webcam-video-buttons">
                {capturing ? (
                <Button onClick={handleStopCaptureClick} className="video-buttons justify-content-center">
                    <StopCircle />
                    <span className="video-btn-text">STOP</span>
                </Button>
                ) : (
                <Button onClick={handleStartCaptureClick} className="video-buttons justify-content-center">
                    <Circle />
                    <span className="video-btn-text">REC</span>
                </Button>
                )}
                {recordedChunks.length > 0 && (
                <Button onClick={handleUpload} className="video-buttons justify-content-center">
                    <Upload />
                    <span className="video-btn-text">UPLOAD</span>
                </Button>
                )}
            </Row>
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg"/>
        </Row>
    );
};

export default WebcamCapture;