import React from 'react';

const ComparisonVideo = ({source, videos}) => {
    return (
        <div className="comparison-video-container">
            {source !== null &&
                <>
                    {videos.map((item, index) => 
                        <React.Fragment key={index}>
                            { item.src === source &&
                                <video autoPlay muted>
                                    <source key={index} src={`${process.env.REACT_APP_API_URL}uploads/videos/${item.src}`} type="video/mp4"/>
                                </video>
                            }
                        </React.Fragment>
                    )}
                </>
            }
        </div>
    );
};

export default ComparisonVideo;