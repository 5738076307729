import React from 'react';
import useFetchFromApi from 'utility/useFetchFromApi';
import Profile from './components/Profile';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './styles.css';

const Profiles = ({ props, onChangeProfile }) => {
  const { loading, error, data, refetch } = useFetchFromApi(`profile/userProfiles`);
  const { t } = useTranslation('profile');

  const selectProfile = (id,data) => {
    localStorage.setItem('profileId', id);
    onChangeProfile(id);
    if(data.length === 0){
      toast.error(t("profile-layout-error"));
    }
  }

  return (
    <div className="profiles-container">
      {data.map((item, index) =>
        <Profile profile={item} selectProfile={selectProfile} key={index}/>
      )}
    </div>
  );
};

export default Profiles;