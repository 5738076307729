import React from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import api from 'utility/api';
import useFetchFromApi from 'utility/useFetchFromApi';
import VideoList from './components/VideoList';
import WebcamCapture from './components/WebcamCapture';
import ComparisonVideo from './components/ComparisonVideo';
import './styles.css';

const FittingRoom = () => {
  const { loading, error, data, refetch } = useFetchFromApi(`clothingVideo/profileVideos/1`);
  
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [thumbnail, setThumbnail] = React.useState(null);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const captureScreenshot = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setThumbnail(imageSrc);
  }, [webcamRef, setThumbnail]);

  const handleStartCaptureClick = React.useCallback(() => {
    captureScreenshot();
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleUpload = React.useCallback(() => {
    if (recordedChunks.length) {
      const videoBlob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      
      const formData = new FormData();
      formData.append('video', videoBlob);
      formData.append('thumbnail', thumbnail);
      formData.append('profileId', process.env.REACT_APP_USER_PROFILE_ID);
      
      api.post(
          'clothingVideo/create',
          formData,
          {
            headers: {
              'X-AUTH-TOKEN': process.env.REACT_APP_ACCESS_TOKEN,
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(function (resp) {
          console.log(resp);
          setRecordedChunks([]);
        })
        .catch(function (err) {
          console.log(err.response);
        })
    }
  }, [recordedChunks]);

  const handleVideoSelection = (src) => {
    setSelectedVideo(src);
  };

  return (
    <div>
      <Row className="mb-3">
        <VideoList
          videos={data}
          handleVideoSelection={handleVideoSelection}
        />
      </Row>
      <Row className="fitting-room-comparison-row">
        <Col sm="6">
          <WebcamCapture
            webcamRef={webcamRef}
            handleStopCaptureClick={handleStopCaptureClick}
            capturing={capturing}
            handleStartCaptureClick={handleStartCaptureClick}
            recordedChunks={recordedChunks}
            handleUpload={handleUpload}
          />
        </Col>
        <Col sm="6">
          <ComparisonVideo
            videos={data}
            source={selectedVideo}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FittingRoom;
  