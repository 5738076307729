import React from 'react';

const AnalogicClock = React.lazy(() => import('widgets/analogic-clock'));
const DigitalClock = React.lazy(() => import('widgets/digital-clock'));
const BasicCalendarWidget = React.lazy(() => import('widgets/basic-calendar'));
const NewsFeed = React.lazy(() => import('widgets/news-feed'));
const GoogleCalendarWidget = React.lazy(() => import('widgets/google-calendar/index'));
const AudioPlayer = React.lazy(() => import('widgets/audio-player'));
const SpotifyPlayerWidget = React.lazy(() => import('widgets/spotify-player'));
const WeatherWidget = React.lazy(() => import('widgets/weather'));
const YoutubePlayerWidget = React.lazy(() => import('widgets/youtube-player'));
const SliderWidget = React.lazy(() => import('widgets/slider'));
const MotivationWidget = React.lazy(() => import('widgets/motivation-widget'));
const IframeWidget = React.lazy(() => import('widgets/iframe-widget'));


const widgets = {
    'ANALOGIC_CLOCK' : AnalogicClock,
    'DIGITAL_CLOCK' : DigitalClock,
    'BASIC_CALENDAR' : BasicCalendarWidget,
    'NEWS_FEED': NewsFeed,
    'GOOGLE_CALENDAR': GoogleCalendarWidget,
    'AUDIO_PLAYER': AudioPlayer,
    'SPOTIFY_PLAYER': SpotifyPlayerWidget,
    'WEATHER': WeatherWidget,
    'YOUTUBE_PLAYER': YoutubePlayerWidget,
    'SLIDER': SliderWidget,
    'MOTIVATION': MotivationWidget,
    'IFRAME_WIDGET': IframeWidget,
};

function renderWidget(widget, props){
    return React.createElement(
        widgets[widget],
        props,
        null
      )
}

export {
    renderWidget
}