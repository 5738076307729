const getRowLimit = (targetRow, dragStart, widgetHeight, gridHeight) => {
  var adjustedTargetRow = targetRow - dragStart;
  if(adjustedTargetRow < 0 ) adjustedTargetRow = 0;
  const maxRow = gridHeight - widgetHeight;
  if (adjustedTargetRow > maxRow) return maxRow;
  return adjustedTargetRow;
};

const getCellLimit = (targetCell, dragStart, widgetWidth, gridWidth) => {
  var adjustedTargetCell = targetCell - dragStart;
  if(adjustedTargetCell < 0 ) adjustedTargetCell = 0;
  const maxCell = gridWidth - widgetWidth;
  if (adjustedTargetCell > maxCell) return maxCell;
  return adjustedTargetCell;
};

export { getRowLimit, getCellLimit };
