import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import './styles.css';

const ConfirmationButton = ({ bodyText, headerText, onConfirm, onCancel, children }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleConfirm = () => {
    toggle();
    onConfirm();
  };

  const handleCancel = () => {
    toggle();
    onCancel();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        contentClassName="confirmation-button-modal p-2"
        backdropClassName="confirmation-button-backdrop"
      >
        <ModalBody>
          <p className="confirmation-button-header"><strong>{headerText}</strong></p>
          <p className="confirmation-button-body-text">{bodyText}</p>
          <Row>
            <Button onClick={handleConfirm} color="danger" className="my-custom-button mt-3">Confirm</Button>
            <Button onClick={handleCancel} color="secondary" className="my-custom-button mt-3">Cancel</Button>
          </Row>
        </ModalBody>
      </Modal>
      { React.cloneElement( children, { onClick: (e) => {
        e.stopPropagation();
        toggle();
        }} ) }
    </>
  );
};

export default ConfirmationButton;