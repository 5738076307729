import React, { useEffect, useState } from 'react';

const SidebarButton = ({ iconUp, iconDown, onUpClick, onDownClick, parameter, disposition}) => {

    const [pointerEvent, setPointerEvent] = useState('none');
    const [disableButton, setDisableButton] = useState('0.4');

    useEffect(() => {
        if(parameter === null){
            setPointerEvent('none');
            setDisableButton('0.4')
        } else {
            setPointerEvent('all');
            setDisableButton('1');
        }

    }, [parameter]);

    return(
     disposition === 'vertical' ? 
        <div className={`d-flex-column justify-content-center align-items-center sidebar-editor-item cursor-pointer mb-1 overflow-hidden`}>
            <div 
                className="d-flex justify-content-center align-items-center w-100 h-50" 
                style={{pointerEvents: pointerEvent, opacity: disableButton }} 
                onClick={() => onUpClick(parameter)}
            >
                {iconUp}
            </div>
            <div 
                className="d-flex justify-content-center align-items-center w-100 h-50" 
                style={{pointerEvents: pointerEvent, opacity: disableButton }} 
                onClick={() => onDownClick(parameter)}
            >
                {iconDown}
            </div>
        </div>
    :
        <div className={`d-flex justify-content-center align-items-center sidebar-editor-item cursor-pointer mb-1 overflow-hidden`}>
            <div 
                className="d-flex justify-content-center align-items-center w-50 h-100" 
                style={{pointerEvents: pointerEvent, opacity: disableButton }} 
                onClick={() => onUpClick(parameter)}
                >
                {iconUp}
            </div>
            <div 
                className="d-flex justify-content-center align-items-center w-50 h-100" 
                style={{pointerEvents: pointerEvent, opacity: disableButton }} 
                onClick={() => onDownClick(parameter)}
            >
                {iconDown}
            </div>
        </div>
    );
};

export default SidebarButton;