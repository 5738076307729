import React, { useState,useEffect } from "react";

const SingleGame = ({singlePlayerGame, setSinglePlayerGame}) =>{
    const [ squares, setSquares ] = useState(Array(9).fill(null));
        const [ isXNext, setIsXNext ] = useState(true);
        const nextSymbol = isXNext ? "X" : "O";
        const [ gameWinner ,setGameWinner] = useState(false);
        const [ nameWinner , setNameWinner] = useState(null); 

    function Square({ value, onClick }) {

        return (
          <div className="square" onClick={onClick}>
            {value}
          </div>
        );
      }
      
      function Restart({ onClick}) {
      
        return (
          <button className="restart" onClick={onClick}>
            Play Again
          </button>
        );
      }
      function Menu({onClick}){
            return (
                <button className="restart" onClick={onClick}>Menu</button>
            );
      }
      const possibleLines = [
          [0, 1, 2],
          [3, 4, 5],
          [6, 7, 8],
          [0, 3, 6],
          [1, 4, 7],
          [2, 5, 8],
          [0, 4, 8],
          [2, 4, 6]
        ];
      
        
        function getStatus() {
          if (gameWinner) {
            return "Winner: " + nameWinner;
          } else if (isBoardFull(squares)) {
            return "Draw!";
          } else {
            return "Next Player: " + nextSymbol;
          }
        }
        
        const computerResult = index => {
          setTimeout(function time(){
            let newSquares = squares;
            newSquares[index] = "O";
            setSquares([...newSquares]);
            setIsXNext(true);
          }, 500);
        };
        
        const linesThatAre = (a,b,c) => {
          return possibleLines.filter(squareIndexes => {
            const squareValues = squareIndexes.map(index => squares[index]);
            return JSON.stringify([a,b,c].sort()) === JSON.stringify(squareValues.sort())
          });
        };
      
      
        const winningPlay = (lines) => {
          const winIndex = lines[0].filter(index => squares[index] === null)[0];
          computerResult(winIndex);
        };
      
        const blockingPlay = (lines) =>{
          const blockIndex = lines[0].filter(index => squares[index] === null)[0];
          computerResult(blockIndex);
        };
      
        const continuePlay = (lines) =>{
          const continueIndex = lines[0].filter(index => squares[index] === null)[0];
          computerResult(continueIndex);
        };
      
      
        useEffect(() => {
          const playerWon = linesThatAre('X','X','X').length > 0;
          const computerWon = linesThatAre ('O','O','O').length > 0;
      
          if(playerWon){
            setNameWinner('X')
            setGameWinner(true);
          }
          else if(computerWon){
            setNameWinner('O')
            setGameWinner(true);
          }else{
            if(!isXNext && !gameWinner && !isBoardFull(squares)){
              const winningLines = linesThatAre('O','O',null);
              const linesToBlock = linesThatAre('X','X',null);
              const linesToContinue = linesThatAre('X',null,null);
        
              if(winningLines.length > 0){
                winningPlay(winningLines);
              }else if(linesToBlock.length > 0){
                blockingPlay(linesToBlock);
              }else{
                continuePlay(linesToContinue);
              }
            }
          }
        }, [isXNext]);
      
      
        function renderSquare(i) {
      
          return (
            <Square
              value={squares[i]}
      
              onClick={() => {
                  if (squares[i] != null){
                    return;
                  }
                  
                  if(isXNext && !gameWinner){
                    const nextSquares = squares.slice();
                  nextSquares[i] = nextSymbol;
                  setSquares(nextSquares);
                  setIsXNext(false);
                  } // toggle turns
                }
                }
            />
          );
        }
      
        function renderRestartButton() {
          return (
            <Restart
              onClick={() => {
                setSquares(Array(9).fill(null));
                setIsXNext(true);
                setGameWinner(false);
              }}
              />
          );
        }
        function renderMenuButton() {
            return(
                <Menu 
                onClick={() => {
                    setSinglePlayerGame(false);
                    setSquares(Array(9).fill(null));
                setIsXNext(true);
                setGameWinner(false);
                }} 
                />
            );
        }
        return (
              <div className="game">
                <span>Single Player</span>
                  <div className="game-info">{getStatus()}</div>
                  <div className="game-board">
                      <table>
                          <tr>
                              <td>{renderSquare(0)}</td>
                              <td>{renderSquare(1)}</td>
                              <td>{renderSquare(2)}</td>
                          </tr>
                          <tr>
                              <td>{renderSquare(3)}</td>
                              <td>{renderSquare(4)}</td>
                              <td>{renderSquare(5)}</td>
                          </tr>
                          <tr>
                              <td>{renderSquare(6)}</td>
                              <td>{renderSquare(7)}</td>
                              <td>{renderSquare(8)}</td>
                          </tr>
                      </table>
                  </div>
                  <div className="restart-button">
                  <div>{renderRestartButton()}</div>
                  <div>{renderMenuButton()}</div>
                  </div>
              </div>
        );
      
      
      function isBoardFull(squares) {
        for (let i = 0; i < squares.length; i++) {
          if (squares[i] == null) {
            return false;
          }
        }
        return true;
      }

}
export default SingleGame;