import React from 'react';
import {
    Save,
    X,
    Plus,
    Minus,
  } from 'react-feather';
import SidebarEditorItem from './SidebarEditorItem';
import SidebarButton from './SidebarButton';
import ConfirmationButton from 'components/confirmation-button';
import './styles.css';

const SidebarEditor = ({ layoutId, goToLayout, saveLayoutChanges, increaseHeight, decreaseHeight, increaseWidth, decreaseWidth,  selectedWidget }) => {

    return (
        <div className="sidebar-wrapper">
            <div className="sidebar-item-list">
                <SidebarButton iconUp={<Plus />} iconDown={<Minus />} disposition="horizontal" onUpClick={increaseWidth} onDownClick={decreaseWidth} parameter={selectedWidget}/>
                <SidebarButton iconUp={<Plus />} iconDown={<Minus />} disposition="vertical" onUpClick={increaseHeight} onDownClick={decreaseHeight} parameter={selectedWidget}/>
                <ConfirmationButton
                    onCancel={() => {return null}}
                    onConfirm={() => saveLayoutChanges()}
                    bodyText="De certeza que pretende guardar alterações ao Layout?"
                    headerText="Guardar alterações"
                 >
                    <SidebarEditorItem text="Save" bgColor="none" icon={<Save />}/>
                </ConfirmationButton>
                <ConfirmationButton
                    onCancel={() => {return null}}
                    onConfirm={() => goToLayout(layoutId)}
                    bodyText="Se cancelar todas as alterações serão perdidas! Tem a certeza que pretende cancelar?"
                    headerText="Cancelar alterações"
                 >
                    <SidebarEditorItem text="Close Editor" icon={<X />}/>
                </ConfirmationButton>
            </div>
        </div>
    );
};

export default SidebarEditor;